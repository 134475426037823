<template>
  <el-select data-testid="deal-select"
    :value="formData.dealUid"
    :disabled="!isSelectedDealValid"
    class="min-width-350px"
    @input="updateDealUid($event)">
    <el-option v-for="deal in filteredDeals"
      :key="deal.entityUid"
      :label="deal | formatDealName"
      :value="deal.entityUid" />
  </el-select>
</template>

<script>
import { DateTime } from 'luxon'

export default {
  name: 'DealSelect',

  props: {
    formData: {
      type: Object,
      required: true,
    },
    deals: {
      type: Array,
      required: true,
    },
    serviceData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      today: DateTime.now().endOf('day').toFormat('y-LL-dd'),
    }
  },

  computed: {
    isSelectedDealValid() {
      const selectedDeal = this.deals.find(deal => deal.entityUid === this.formData.dealUid)
      return this.isDealValid(selectedDeal)
    },
    filteredDeals() {
      return this.isSelectedDealValid ? this.deals.filter(deal => this.isDealValid(deal)) : this.deals
    },
  },

  methods: {
    formatDate(date, addDays = 0) {
      if (!date) return null
      return DateTime.fromMillis(date).endOf('day').plus({ days: addDays }).toFormat('y-LL-dd')
    },
    isDealValid(deal) {
      if (!deal) return false
      if (deal.dealId === '') return true
      // checking if deal order window is active today, i.e. not expired
      const isDealAlive = (this.today >= this.formatDate(deal.orderWindowStartDate) && this.today <= this.formatDate(deal.orderWindowEndDate))
      // checking if service created date in between deal window
      // deal is expired if (service creation date + 30days) < deal order window start date
      // 1 day grace period is given to deal order window end date to accommodate timezone differences
      const validDeal = (this.formatDate(this.serviceData.createDate, 30) >= this.formatDate(deal.orderWindowStartDate)
        && this.formatDate(this.serviceData.createDate) <= this.formatDate(deal.orderWindowEndDate, 1))
      return validDeal && isDealAlive
    },
    updateDealUid(value) {
      this.$emit('updateDealUid', value)
    },
  },
}
</script>
