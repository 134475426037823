<template>
  <el-dialog :visible="visible"
    :before-close="handleClose"
    :title="$t(isPartnerVantage ? 'partner-vantage.subscription-title' : 'port-term.title', { productType })">
    <div v-loading="!ready">
      <!-- Funky Pricing Disclaimer -->
      <template v-if="funkyPricing">
        <p class="text-align-center">
          {{ $t('port-term.funky-1') }}
        </p>
        <p class="text-align-center">
          {{ portTermFunky }}
        </p>
      </template>

      <!-- Contract Term / Subscription Form -->
      <el-form v-else-if="selectOptions.length"
        ref="termForm"
        :model="termForm"
        :rules="termRules"
        label-position="top">
        <!-- Contract Term / Subscription -->
        <el-form-item prop="newTerm"
          :label="$t(isPartnerVantage ? 'partner-vantage.subscription-choose' : 'port-term.choose', { productType })">
          <!-- Contract Term / Subscription Options -->
          <el-radio v-for="option in selectOptions"
            :key="option.term"
            v-model="termForm.newTerm"
            :label="option.term"
            :data-term-option="option.term"
            :data-term-discount="option.discountRate"
            data-name="term-option"
            data-testid="term-option"
            class="d-block line-height-3">
            {{ option.label }}
          </el-radio>
        </el-form-item>

        <template v-if="serviceIsVxc && rateLimitIsEditable">
          <!-- Rate Limit -->
          <input-ratelimit prop="rateLimit"
            :label="$t('services.rate-limit')"
            :help-text="serviceIsIx ? $t('connections.ix-rate-limit-help') : $t('connections.vxc-rate-limit-help')"
            :value="termForm.rateLimit"
            :min-rate-limit="temporaryMinRateLimit"
            :max-rate-limit="maxRateLimit"
            :fixed-bandwidths="fixedBandwidths"
            :fixed-speed-service="fixedSpeedService"
            :fixed-rate="false"
            :higher-speed-url="higherSpeedUrl"
            :provisioning-status="port.provisioningStatus"
            :disabled="!termForm.newTerm"
            @input="(newRate) => termForm.rateLimit = newRate" />

          <!-- Minimum Term Disclaimer -->
          <el-collapse-transition>
            <el-alert v-if="termForm.newTerm"
              type="warning"
              :closable="false"
              class="mt-2 py-0-5 line-height-initial">
              <p class="color-warning">
                {{ $t('connections.minimum-term-disclaimer') }}
              </p>
              <p class="color-warning">
                {{ $t('connections.termed-delete-etf') }}
              </p>
            </el-alert>
          </el-collapse-transition>
        </template>

        <!-- Disclaimer -->
        <div class="warning-message"
          :class="serviceIsVxc && rateLimitIsEditable ? 'mt-2' : ''">
          <strong class="d-block mb-2">{{ $t('general.important-information') }}</strong>
          <div v-html="legalParagraph" /> <!-- eslint-disable-line vue/no-v-html -->
          <el-collapse-transition>
            <div v-if="termForm.newTerm"
              class="mt-2">
              {{ $t(isPartnerVantage ? 'partner-vantage.subscription-reterm' : 'port-term.reterm') }}
              <el-form-item prop="agreeTerms"
                class="mt-2 agree-terms">
                <el-checkbox v-model="termForm.agreeTerms"
                  name="agreeToTerms"
                  data-testid="agree-to-terms">
                  {{ $t('general.agree-terms') }}
                </el-checkbox>
              </el-form-item>
            </div>
          </el-collapse-transition>
        </div>
      </el-form>

      <el-alert v-if="isRetermImmediate"
        type="info"
        :closable="false"
        center
        show-icon
        class="mt-2 py-0 px-2">
        <template #title>
          <p>{{ $t(isPartnerVantage ? 'partner-vantage.cancel-subscription' : 'general.cancel-reterm') }}</p>
        </template>
      </el-alert>
    </div>
    <div slot="footer">
      <!-- Start New Contract Term / Subscription -->
      <el-button v-if="termForm.newTerm"
        type="primary"
        :disabled="startingNewContract"
        name="saveNewTerm"
        @click="saveTerm">
        {{ $t(isPartnerVantage ? 'partner-vantage.confirm-new-subscription' : 'port-term.confirm-new-term') }}
      </el-button>
      <el-button data-name="term-panel-close"
        @click="setVisible(false)">
        {{ $t('general.cancel') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
// External Tools
import { mapActions, mapGetters, mapMutations } from 'vuex'
// Internal Tools
import sdk from '@megaport/api-sdk'
import captureSentryError from '@/utils/CaptureSentryError.js'
import { moneyFilter, convertProductType } from '@/helpers.js'
// Components
import InputRatelimitComponent from '@/components/InputRatelimit.vue'
// Globals
import {
  LEGAL_PARAGRAPH_MANAGED_PARTNER,
  LEGAL_PARAGRAPH_MANAGED_USER,
  G_PRODUCT_TYPE_VXC,
  G_PRODUCT_TYPE_IX,
} from '@/Globals.js'

export default {
  name: 'PortTerm',

  components: {
    'input-ratelimit': InputRatelimitComponent,
  },

  inject: ['disabledFeatures', 'isFeatureEnabled'],

  props: {
    visible: {
      type: Boolean,
    },
    port: {
      type: Object,
      required: true,
    },
    minRateLimit: {
      type: Number,
      required: false,
      default: null,
    },
    maxRateLimit: {
      type: Number,
      required: false,
      default: null,
    },
    fixedBandwidths: {
      type: [Array, Boolean],
      required: false,
      default: null,
    },
    fixedSpeedService: {
      type: Boolean,
      required: false,
      default: false,
    },
    higherSpeedUrl: {
      type: String,
      required: false,
      default: null,
    },
    rateLimitIsEditable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    const isVxc = this.port.productType === G_PRODUCT_TYPE_VXC

    const termForm = {
      newTerm: null,
      agreeTerms: false,
      ...isVxc ? { rateLimit: this.port.rateLimit } : {},
    }

    const termRules = {
      newTerm: { required: true, message: this.$t('general.please-select'), trigger: 'change' },
      agreeTerms: { required: true, validator: this.validateBooleanTrue, trigger: 'change' },
      ...isVxc ? { rateLimit: { required: true, validator: this.validateRateLimit, trigger: 'blur' } } : {},
    }

    return {
      ready: false,
      selectOptions: [],
      termForm,
      termRules,
      funkyPricing: false,
      startingNewContract: false,
      abortController: null,
    }
  },

  computed: {
    ...mapGetters('Auth', ['hasFeatureFlag', 'isPartnerAccount', 'isManagedAccount', 'isPartnerSupported', 'isPartnerVantage']),

    showPricing() {
      return !this.disabledFeatures.showPrices && this.isFeatureEnabled('PRICING_VISIBLE')
    },
    legalParagraph() {
      if (this.isPartnerAccount) return LEGAL_PARAGRAPH_MANAGED_PARTNER

      if (this.isManagedAccount) return LEGAL_PARAGRAPH_MANAGED_USER

      return process.env.VUE_APP_LEGAL_PARAGRAPH
    },
    portTermFunky() {
      return this.isPartnerSupported ? this.$t('port-term.funky-3') : this.$t('port-term.funky-2')
    },
    productType() {
      return convertProductType(this.port.productType)
    },
    isRetermImmediate() {
      const retermImmediateProductTypes = [
        this.G_PRODUCT_TYPE_MCR2,
        this.G_PRODUCT_TYPE_MVE,
        this.G_PRODUCT_TYPE_VXC,
      ]

      return retermImmediateProductTypes.includes(this.port.productType)
    },
    serviceIsVxc() {
      return this.port.productType === G_PRODUCT_TYPE_VXC
    },
    serviceIsIx() {
      return this.port.productType === G_PRODUCT_TYPE_IX
    },
    temporaryMinRateLimit() {
      if (this.hasFeatureFlag('TERMED_VXC_SPEED_CHANGE')) {
        return this.minRateLimit ?? this.port.rateLimit
      } else {
        return this.port.rateLimit
      }
    },
  },

  watch: {
    'termForm.rateLimit'() {
      if (this.serviceIsVxc && this.rateLimitIsEditable) this.fetchTerms()
    },
  },

  created() {
    this.initForm()
  },

  methods: {
    ...mapMutations('Notifications', ['notifyGood']),
    ...mapActions('Services', ['editConnection', 'editService', 'fetchServiceOrConnection']),

    validateBooleanTrue(_rule, value, callback) {
      if (typeof value !== 'boolean' || !value) {
        callback(new Error(this.$t('validations.agree-terms')))
      } else {
        callback()
      }
    },
    validateRateLimit(_rule, value, callback) {
      if (value < this.temporaryMinRateLimit || value > this.maxRateLimit) {
        callback(this.$t('validations.mbps-value-range', { min: this.temporaryMinRateLimit, max: this.maxRateLimit }))
      } else if (!/^\d+$/.test(value)) {
        callback(this.$t('validations.value-integer'))
      } else {
        callback()
      }
    },
    setVisible(newValue) {
      this.$emit('update:visible', newValue)
    },
    handleClose(done) {
      this.setVisible(false)
      done()
    },
    async initForm() {
      try {
        await this.fetchTerms()

      } catch (error) {
        // If we get an error for any reason, we will treat it as "funky pricing" and display it with the
        // appropriate message, since they can contact their sales rep and go from there.
        this.funkyPricing = true

        // If it's not a 409 error then it's not a genuine funky price, so report it to Sentry.
        if (error.status !== 409) captureSentryError(error)
      } finally {
        this.ready = true
      }
    },
    populateSelectOptions(data) {
      const selectOptions = []

      for (const { availability, term, monthlyRate, currency, discountRate } of data) {
        if (availability !== 'unavailable') {
          const label = this.showPricing && availability !== 'no-price'
            // With the new pricing changes, we no longer show the discount percent.
            ? this.$tc('port-term.option-price', term, {
                count: term,
                cost: moneyFilter(monthlyRate, currency),
              })
            : this.$tc('general.count-months', term)

          selectOptions.push({ term, label, discountRate })
        }
      }

      this.selectOptions = selectOptions
    },
    saveTerm() {
      this.$refs.termForm.validate(valid => {
        if (!valid) {
          const props = {
            title: this.$t('validations.failed'),
            message: this.$t('validations.correct-issues'),
            type: 'error',
            duration: 3000,
          }

          this.$notify(props)

          return false
        }

        this.ready = false
        this.startingNewContract = true

        const isConnection = this.serviceIsVxc || this.serviceIsIx

        if (isConnection) {
          const { newTerm, rateLimit } = this.termForm

          const connection = { ...this.port, contractTermMonths: newTerm, rateLimit }

          this.editConnection({ connection, updateTerm: true })
            .then(() => {
              // Notify user update was successful
              this.notifyGood({
                title: this.$t('port-term.update-success', {
                  productType: this.productType,
                }),
                message: this.$tc('port-term.reterm-success', this.termForm.newTerm, {
                  portName: this.port.productName,
                  count: this.termForm.newTerm,
                }),
              })

              this.setVisible(false)
            })
            .catch(e => {
              console.error(e)
              this.$notify.error({
                title: this.$t('port-term.update-failure'),
                message: this.$t('port-term.update-error'),
              })

              captureSentryError(e)
            })
            .finally(() => {
              this.ready = true
              this.startingNewContract = false
            })
        } else {
          const { newTerm } = this.termForm

          const service = { ...this.port, contractTermMonths: newTerm }

          this.editService({ service, updateTerm: true })
            .then(() => {
              // Notify user update was successful
              this.notifyGood({
                title: this.$t('port-term.update-success', {
                  productType: this.productType,
                }),
                message: this.$tc('port-term.reterm-success', this.termForm.newTerm, {
                  portName: this.port.productName,
                  count: this.termForm.newTerm,
                }),
              })

              // Trigger an immediate reload of the data so it will reflect on the port page
              const params = {
                productUid: this.port.productUid,
                incResources: true,
                periodic: true,
              }

              this.fetchServiceOrConnection(params)
                .then(() => {
                  // Reset the pricing cache in case it has changed to a different pricing model
                  sdk.instance.resetPriceBookCache()
                })
                .catch(() => { /* empty */ })

              this.setVisible(false)
            })
            .catch(e => {
              this.$notify.error({
                title: this.$t('port-term.update-failure'),
                message: this.$t('port-term.update-error'),
              })

              captureSentryError(e)
            })
            .finally(() => {
              this.ready = true
              this.startingNewContract = false
            })
        }
      })
    },
    async fetchTerms() {
      this.abortController?.abort()
      this.abortController = new AbortController()

      // Only pass the rate limit to the API if it's editable
      const speed = this.serviceIsVxc && this.rateLimitIsEditable
        ? this.termForm.rateLimit
        : null

      // Get the available options from the API
      const data = await sdk.instance
        .product(this.port.productUid)
        .getPortTerms(speed, { signal: this.abortController.signal })

      this.populateSelectOptions(data)
    },
  },
}
</script>

<style lang="scss" scoped>
.agree-terms::v-deep .el-form-item__error {
  bottom: -0.6rem;
  margin-left: 0;
}
.warning-message {
  border: 1px solid var(--color-warning);
  border-radius: var(--border-radius-base);
  padding: 1rem 2rem;
  word-break: normal;
  word-wrap: break-word;
}
</style>
