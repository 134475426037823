<script>
import sdk from '@megaport/api-sdk'
import { G_PRODUCT_TYPE_CXC, G_PRODUCT_TYPE_VXC } from '@/Globals'
import { downloadFile } from '@/utils/downloadFile'

export default {
  props: {
    productUid: {
      type: String,
      required: true,
    },
    productType: {
      type: String,
      required: true,
    },
    dataType: {
      type: String,
      required: true,
    },
    from: {
      type: Number,
      required: true,
    },
    to: {
      type: Number,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'small',
      required: false,
    },
    icon: {
      type: String,
      default: 'el-icon-download',
      required: false,
    },
  },
  emits: ['downloading'],
  computed: {
    // The product type is converted to lowercase, except for CXC which is converted to VXC, as required by the API
    productTypeLower() {
      return (this.productType === G_PRODUCT_TYPE_CXC ? G_PRODUCT_TYPE_VXC : this.productType).toLocaleLowerCase()
    },
    downloadUrl() {
      return `${sdk.instance.baseurl}/v2/product/${this.productTypeLower}/${this.productUid}/telemetry?type=${this.dataType}&to=${this.to}&from=${this.from}`
    },
  },
  methods: {
    async download() {
      try {
        this.$emit('downloading', true)
        // Errors are handled by the downloadFile function
        await downloadFile(this.downloadUrl, { additionalHeaders: { Accept: 'text/csv' } })
      } finally {
        this.$emit('downloading', false)
      }
    },
  },
}
</script>

<template>
  <el-button :icon="icon"
    :size="size"
    @click="download">
    {{ buttonText }}
  </el-button>
</template>
