<template>
  <div>
    <template v-if="!loading">
      <div v-if="infoBarText"
        class="info-bar">
        {{ infoBarText }}
      </div>

      <p v-if="logsToDisplay.length >= 100"
        class="text-align-right font-weight-500 pr-1">
        {{ $t('general.most-recent-n-events', 100, {count: 100}) }}
      </p>
    </template>

    <el-table v-loading="loading"
      :data="logsToDisplay"
      max-height="450"
      stripe
      :border="true"
      :show-header="true"
      class="data-table hide-table-header">
      <el-table-column width="37">
        <template #default="{row}">
          <i v-if="row.good"
            class="fas fa-check-circle color-success"
            aria-hidden="true" />
          <i v-else
            class="fas fa-exclamation-triangle color-warning"
            aria-hidden="true" />
        </template>
      </el-table-column>
      <el-table-column prop="message" />
      <el-table-column prop="timeDisplay"
        width="450" />
    </el-table>
  </div>
</template>

<script>
import { mpDate } from '@/helpers.js'
import sdk from '@megaport/api-sdk'
export default {
  props: {
    serviceId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      logs: null,
      info: false,
      loading: true,
    }
  },
  computed: {
    logsToDisplay() {
      if (!this.logs) return []

      return this.logs
        .filter(log => log && log.event && log.time)
        .sort((a, b) => b.time - a.time)
        .map(log => ({
          message: log.message,
          good: log.event.toLowerCase().includes('down') ? false : true,
          time: log.time,
          timeDisplay: mpDate(log.time),
        }))
        .slice(0, 100) // Return most recent 100 logs
    },
    recentLogsData() {
      if (!this.logsToDisplay) return { last24HrsLogCount: 0, lastLog: 0 }

      const time24HrsAgo = Date.now() - 1000 * 60 * 60 * 24

      const last24HrsLogCount = this.logsToDisplay.filter(log => (log?.time ? log.time > time24HrsAgo : false)).length

      const lastLog = this.logsToDisplay.length > 0 ? this.logsToDisplay[0].timeDisplay : 0

      return { last24HrsLogCount, lastLog }

    },
    infoBarText() {
      // No logs to display
      if (this.logsToDisplay.length < 1) return this.$t('general.no-logs')

      // Recent logs info text
      const { last24HrsLogCount, lastLog } = this.recentLogsData

      if (last24HrsLogCount > 0) {
        const logCountSubstring = this.$tc('general.logs-count', last24HrsLogCount, { count: last24HrsLogCount })
        const lastLogSubString = this.$t('general.last-log', { last: lastLog })

        return `${logCountSubstring} - ${lastLogSubString}`
      }

      return null
    },
  },
  watch: {
    serviceId() {
      this.fetchLogs()
    },
  },
  created() {
    this.fetchLogs()
  },
  methods: {
    fetchLogs() {
      if (!this.serviceId) return
      sdk.instance
        .product(this.serviceId)
        .logs()
        .then(logs => {
          this.logs = logs
          this.loading = false
        })
        .catch(() => {
          // TODO: Should we be capturing exceptions here?
          this.logs = null
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.info-bar {
  background-color: var(--color-white);
  border: 1px solid var(--color-warning);
  border-radius: var(--border-radius-base);
  padding: 2rem;
  text-align: center;
  width: fit-content;
  margin: auto;
  margin-bottom: 2rem;
  line-height: normal;
}
.data-table {
  width: calc(100% - 2px); // Avoids jitter on Chrome Windows
  margin-bottom: 2rem;
}
</style>
